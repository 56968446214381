<template>
  <v-navigation-drawer v-model="drawer" app width="300">
    <v-list-item class="my-1">
      <v-list-item-avatar>
        <v-avatar size="48" tile>
          <v-btn fab small dark @click="$router.push({ name: 'Root' })">
            <span class="white--text headline">{{ nameToInitials }}</span>
          </v-btn>
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-title><h3>{{ $store.state.userProfile.name }}</h3></v-list-item-title>
    </v-list-item>
    <v-divider></v-divider>

    <v-list>
      <div v-for="menu in filteredMenus" :key="menu.title">
        <v-list-group>
          <template v-slot:activator>
            <v-icon v-if="menu.icon">{{ menu.icon }}</v-icon>
            <v-list-item>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item>
          </template>
          <v-list dense expand>
            <template v-for="(item, i) in menu.data">
              <!--group with subitems-->
              <v-list-group v-if="item.items" :key="item.name" :group="item.group" no-action="no-action">
                <v-list-item slot="activator" ripple="ripple">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <template v-for="(subItem, i) in item.items">
                  <!--sub group-->
                  <v-list-group v-if="subItem.items" :key="subItem.name" :group="subItem.group" sub-group="sub-group">
                    <v-list-item slot="activator" ripple="ripple">
                      <v-list-item-content>
                        <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-for="(grand, i) in subItem.children" :key="i" :to="genChildTarget(item, grand)" :href="grand.href" ripple="ripple">
                      <v-list-item-content>
                        <v-list-item-title>{{ grand.title }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                  <!--child item-->
                  <v-list-item v-else :key="i" :to="genChildTarget(item, subItem)" :href="subItem.href" :disabled="subItem.disabled" :target="subItem.target" ripple="ripple">
                    <v-list-item-content>
                      <v-list-item-title><span>{{ subItem.title }}</span></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="subItem.action">
                      <!-- eslint-disable-next-line -->
                      <v-icon :class="[subItem.actionClass || 'success--text']">{{ subItem.action }}</v-icon>=
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list-group>
              <v-subheader v-else-if="item.header" :key="i">{{ item.header }}</v-subheader>
              <v-divider v-else-if="item.divider" :key="i"></v-divider>
              <!--top-level link-->
              <v-list-item v-else :to="!item.href ? { name: item.name } : null" :href="item.href" ripple="ripple" :disabled="item.disabled" :target="item.target" rel="noopener" :key="item.name">
                <v-list-item-action v-if="item.icon">
                  <v-icon class="pl-2">{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="item.subAction">
                  <v-icon class="success--text">{{ item.subAction }}</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import buyerSidebar from "../config/buyerSidebar";
import mobileSidebar from "../config/mobileSidebar";
import commonSidebar from "../config/commonSidebar";
import vendorSideBar from "../config/vendorSidebar";
import mobileSidebarV2 from "../config/mobileSidebarV2"
import websiteSidebar from '../config/websiteSidebar'

export default {
  data() {
    return {
      drawer: true,
      menus: [
        {
          title: "Common",
          data: commonSidebar,
          icon: "account_box",
        },
        {
          title: "Buyer Dashboard",
          data: buyerSidebar,
          icon: "dashboard",
        },
        {
          title: "Mobile System",
          data: mobileSidebar,
          icon: "account_box",
        },
        {
          title: "Vendors",
          data: vendorSideBar,
          icon: "account_box",
        },
        {
          title: "Mobile V2",
          data: mobileSidebarV2,
          icon: "install_mobile",
        },
        {
          title: "Website",
          data: websiteSidebar,
          icon: "language",
        },
      ],
      scrollSettings: {
        maxScrollbarLength: 160,
      },
    }
  },
  created() {
    window.getApp.$on('APP_DRAWER_TOGGLED', () => {
      this.drawer = (!this.drawer);
    });
  },
  methods: {
    genChildTarget(item, subItem) {
      if (subItem.href) return;
      if (subItem.component) {
        return {
          name: subItem.component,
        };
      }
      return { name: `${item.group}/${subItem.name}` };
    },
    hasPermission(permission) {
      return this.$store.state.adminUser.permissions.includes(permission);
    }
  },
  computed: {
    nameToInitials() {
      let name = this.$store.state.userProfile.name
      return name.split(" ").map(x => x.charAt(0)).join("").substr(0, 2).toUpperCase();
    },
    filteredMenus() {
      return this.menus.map((menu) => {
        return {
          ...menu,
          data: menu.data.filter(item => !item.permission || this.hasPermission(item.permission))
        }
      })
    }
  },
};
</script>